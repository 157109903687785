import './index.css';
import {Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import TermsAndConditions from "./components/TermsAndConditions";
import Privacy from "./components/Privacy";
import DeleteAccount from "./components/DeleteAccount";
import NotFound from "./components/NotFound";
import GetTheApp from "./components/GetTheApp";
import Safety from "./components/Safety";

function App() {
  return (
      <Routes>
        <Route path="/" element={<Home/> } />
        <Route path="/home" element={<Home/> } />
        <Route path="/terms" element={<TermsAndConditions/> } />
        <Route path="/privacy" element={<Privacy/> } />
        <Route path="/safety" element={<Safety/> } />
        <Route path="/deleteAccount" element={<DeleteAccount/> } />
        <Route path="/app/*" element={<GetTheApp />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
  );
}

export default App;
