import Footer from "./Footer";

function Safety() {
    return (
        <div className="privacy privacySite">
            <div className="chevronBack"><a href="/" style={{"textDecoration": "none"}}>‹</a></div>
            <div className="logo-right">
                <a href="/">
                    <img alt="logo" src="/img/heart.png" width="40"></img>
                    <span style={{color: "#99627A"}}>SwiftCrush</span>
                </a>
            </div>
            <div className="story">
                <h1 className="pink">Safety Standards</h1>
                <div className="container">
                    <div className="half">
                        <div className="mission-text">
                            At SwiftCrush, we are committed to providing a safe and respectful environment for all our users. 
                            Our policies and practices are aligned with global standards to combat Child Sexual Abuse and Exploitation (CSAE).
                        </div>
                    </div>
                    <div className="half privacyUl">
                        <span>These Safety Standards are effective immediately.</span>
                        <ul>
                            <li><a href="#section1">Zero Tolerance Policy</a></li>
                            <li><a href="#section2">User Verification and Age Restriction</a></li>
                            <li><a href="#section3">Detection and Reporting Mechanisms</a></li>
                            <li><a href="#section4">Collaboration with Authorities</a></li>
                            <li><a href="#section5">User Education</a></li>
                            <li><a href="#section6">Regular Policy Updates</a></li>
                            <li><a href="#section7">Contact Information</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="section1">
                <div className="mission-text-terms">Zero Tolerance Policy</div>
                <div className="terms-text">
                    SwiftCrush strictly prohibits any content, behavior, or activities that involve child sexual abuse and exploitation. This includes but is not limited to:
                    <ul>
                        <li>Sharing, uploading, or exchanging sexually explicit content involving minors</li>
                        <li>Grooming or attempting to engage with minors inappropriately</li>
                        <li>Impersonating minors to exploit or harm others</li>
                    </ul>
                    Violations of this policy will result in immediate account suspension or termination and reporting to appropriate legal authorities.
                </div>
            </div>

            <div id="section2">
                <div className="mission-text-terms">User Verification and Age Restriction</div>
                <div className="terms-text">
                    SwiftCrush is a platform exclusively for adults aged 18 and above. We utilize advanced age verification systems during account creation to prevent minors from accessing our services. In addition to automated systems, our dedicated team performs routine manual checks to verify user profiles and ensure compliance with our age restrictions.
                </div>
            </div>

            <div id="section3">
                <div className="mission-text-terms">Detection and Reporting Mechanisms</div>
                <div className="terms-text">
                    We employ the following technologies and practices to identify and mitigate CSAE risks:
                    <ul>
                        <li><b>AI-Powered Content Moderation:</b> Automated tools to flag and remove inappropriate images, videos, and text</li>
                        <li><b>Human Review Teams:</b> A dedicated team of moderators to review flagged content promptly and thoroughly</li>
                        <li><b>User Reporting System:</b> SwiftCrush provides easy-to-use reporting tools for users to report suspicious or harmful activities</li>
                    </ul>
                </div>
            </div>

            <div id="section4">
                <div className="mission-text-terms">Collaboration with Authorities</div>
                <div className="terms-text">
                    SwiftCrush works closely with law enforcement and child protection organizations to:
                    <ul>
                        <li>Report CSAE incidents swiftly and effectively</li>
                        <li>Share evidence, as permitted by law, to support investigations</li>
                    </ul>
                    We adhere to global reporting standards to address child sexual abuse and exploitation effectively.
                </div>
            </div>

            <div id="section5">
                <div className="mission-text-terms">User Education</div>
                <div className="terms-text">
                    We educate our users about:
                    <ul>
                        <li>Recognizing and reporting CSAE</li>
                        <li>Understanding the consequences of inappropriate behavior</li>
                    </ul>
                    Through blog posts, in-app notifications, and FAQs, we strive to raise awareness and empower users to act responsibly.
                </div>
            </div>

            <div id="section6">
                <div className="mission-text-terms">Regular Policy Updates</div>
                <div className="terms-text">
                    Our policies are reviewed and updated periodically to address emerging threats and ensure compliance with the latest regulations and best practices.
                </div>
            </div>

            <div id="section7">
                <div className="mission-text-terms">Contact Information</div>
                <div className="terms-text">
                    If you encounter any content or behavior violating these standards, please report it immediately through our in-app reporting tools or contact our Safety Team at:
                    <br/><br/>
                    <b className="pink">Safety Team</b>: <a href="mailto:safety@swiftcrush.com" style={{color: "black"}}>safety@swiftcrush.com</a><br/>
                    <b className="pink">Law Enforcement Inquiries</b>: <a href="mailto:lawenforcement@swiftcrush.com" style={{color: "black"}}>lawenforcement@swiftcrush.com</a><br/>
                    <br/>
                    For more details, visit our Safety and Privacy Center at <a href="https://swiftcrush.com/safety" style={{textDecoration: "none"}}><b className="pink">swiftcrush.com/safety</b></a>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Safety; 